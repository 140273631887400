<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="company_name"
        type="text"
        name="Ragione Sociale"
        hint="Ricerca per Ragione Sociale"
        dense
        :sm="4"
        :md="2"
        :lg="2"
        :xl="3"
      />
      <FormItem
        v-model="last_name"
        type="text"
        name="Cognome Cliente"
        hint="Ricerca per Cognome Cliente"
        dense
        :sm="4"
        :md="2"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="first_name"
        type="text"
        name="Nome Cliente"
        hint="Ricerca per Nome Cliente"
        dense
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
      />
      <FormItem
        v-model="cf"
        type="text"
        name="P.IVA / CF"
        hint="Ricerca per Partita IVA o CF"
        :sm="4"
        :md="2"
        :lg="3"
        :xl="3"
        dense
      />

      <FormItem
        v-model="subsid"
        type="text"
        name="ID Sottoscrizione"
        dense
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
      />

      <FormPlans
        v-model="plan_id"
        type="text"
        name="Piano"
        hint="Ricerca per Piano"
        dense
        multi
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
      />

      <FormItem
        v-model="reqtype"
        type="autocomplete"
        :values="reqtypes"
        name="Tipo"
        hint="Ricerca per Tipo"
        dense
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
      />

      <FormItem
        v-model="status"
        type="autocomplete"
        name="Stato"
        hint="Ricerca per Stato"
        :values="statuses"
        multiple
        dense
        :sm="4"
        :md="2"
        :lg="2"
        :xl="2"
      />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapMutations, mapState, mapGetters } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'
import FormPlans from '@components/forms/FormPlans.vue'

const { mapFields } = createHelpers({
  getterType: 'timopenRequests/getFiltersFields',
  mutationType: 'timopenRequests/SET_FILTER_FIELDS',
})

export default {
  name: 'TimOpenRequestsSectionSearch',
  components: { FormPlans, FormItem, BaseSectionSearch },
  computed: {
    ...mapFields([
      'company_name',
      'first_name',
      'last_name',
      'cf',
      'plan_id',
      'reqtype',
      'status',
      'subsid',
    ]),
    ...mapState('timopenRequests', ['filters', 'types']),
    ...mapGetters('timopenRequests', ['statuses', 'reqtypes']),
  },
  methods: {
    ...mapMutations('timopenRequests', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
