<template>
  <v-container fluid>
    <TimOpenRequestsSectionSearch @search="() => $refs.table.refresh()" />

    <BaseTable
      ref="table"
      title="Ordini TIM Digital Store"
      module="timopenRequests"
      :actions="actions"
      :headers="headers"
      :exportable="canUser('timopen_requests', 'export')"
    >
      <template v-slot:item.company_name="{ item }">
        <strong>{{ item.company_name }}</strong>
      </template>
      <template v-slot:item.reqtype="{ item }">
        <v-chip label>{{ item.reqtype }}</v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip label :color="statusColor(item.status)">{{
          statusLabel(item.status)
        }}</v-chip>
      </template>
    </BaseTable>
  </v-container>
</template>

<script>
import BaseTable from '@components/structure/base-table.vue'
import { mapGetters, mapMutations } from 'vuex'
import TimOpenRequestsSectionSearch from '@components/timeopenrequests/TimOpenRequestsSectionSearch.vue'
import { authComputed } from '@state/helpers.js'

export default {
  name: 'TimopenRequests',
  page: {
    title: 'Elenco Richieste Tim Digital Store',
  },
  components: { TimOpenRequestsSectionSearch, BaseTable },
  data() {
    return {
      headers: [
        {
          text: 'Tipo',
          value: 'reqtype',
        },
        {
          text: 'Stato',
          value: 'status',
        },
        {
          text: 'ID',
          value: 'subsid',
        },
        {
          text: 'Regione Sociale',
          value: 'company_name',
        },
        {
          text: 'Cognome',
          value: 'last_name',
        },
        {
          text: 'Nome',
          value: 'first_name',
        },
        {
          text: 'P.IVA / CF',
          value: 'piva',
        },
        {
          text: 'Piano',
          value: 'plan_name',
        },
        {
          text: 'Data Creazione',
          value: 'created_at',
        },
        {
          text: 'Ultima modifica',
          value: 'updated_at',
        },
      ],
      actions: [
        {
          label: 'Scheda Cliente',
          icon: 'mdi-magnify',
          color: 'primary',
          button: true,
          to: (item) => ({
            name: 'customers_detail',
            params: { id: item.customer_id },
          }),
          onItemCondition: () => this.canUser('customers', 'read'),
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters('timopenRequests', ['statusLabel', 'statusColor']),
  },
  mounted() {
    this.setScopes(['customer', 'subscription'])
  },
  beforeDestroy() {
    this.setScopes([])
  },
  methods: {
    ...mapMutations('timopenRequests', {
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
      setScopes: 'SET_SCOPES',
    }),
  },
}
</script>
